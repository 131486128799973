<template>
  <v-container fluid tag="section" aria-labelledby="title">
    <v-row>
      <v-col cols="12">
        <v-card class="elevation-0 rounded-md v-card--variant-elevated">
          <v-card-item>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-card-title
                  class="text-md-h6 text-body-1 text-wrap font-weight-bold text-md-left"
                  >Добро пожаловать {{ this.$route.params.objectParam }}</v-card-title
                >
              </v-col>

              <v-col cols="12" sm="12" md="4" offset-md="2">
                <div class="td_actions_btn">
                  <div class="d-flex justify-end">
                    <v-btn
                      density="comfortable"
                      class="text-none"
                      color="brown-lighten-1"
                      elevation="1"
                      prepend-icon="mdi-plus"
                      to="/CalendarForm"
                      >Добавить событие</v-btn
                    >
                    <v-btn
                      density="comfortable"
                      class="float-md-right text-none"
                      color="brown-lighten-1"
                      elevation="1"
                      prepend-icon="mdi-pencil"
                      to="/edit"
                      >Редактировать</v-btn
                    >
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-item>
          <v-divider></v-divider>
          <v-card-text class="elevation-0 rounded-md v-card--variant-elevated">
            <FullCalendar
              v-touch:swipe="swipeHandler"
              ref="fullCalendar"
              :options="{
                ...calendarOptions,
                events: filteredList,
              }"
            >
              <template v-slot:eventContent="arg">
                <b v-if="arg.event.allDay == true"
                  >Весь день {{ arg.event.extendedProps.address }}</b
                >
                <div class="timeHeader" v-else>
                  <div class="timeRound">
                    <div>
                      <span v-if="arg.event.extendedProps.notify">🔔&nbsp;</span
                      >{{ timeView(arg.event.start) }}
                    </div>
                    <div
                      v-if="arg.event.allDay == false && arg.event.end != arg.event.start"
                    >
                      &nbsp;- {{ timeView(arg.event.end) }}
                    </div>
                  </div>
                  <div>{{ arg.event.extendedProps.address }}</div>
                </div>
                <div>{{ arg.event.title }}</div>
              </template>
            </FullCalendar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="480">
      <v-card>
        <template v-slot:text>
          <v-card-title class="text-body-1 font-weight-bold text-wrap pa-0 mb-3">{{
            dialogEvent.title
          }}</v-card-title>
          <div><b>Адрес:</b> {{ dialogEvent.extendedProps.address }}</div>
          <div v-if="!dialogEvent.end">
            <div><b>Дата:</b> {{ dateView(dialogEvent.start) }}</div>
          </div>
          <div v-else>
            <div><b>Начало периода:</b> {{ dateView(dialogEvent.start) }}</div>
            <div><b>Конец периода:</b> {{ dateView(dialogEvent.end) }}</div>
          </div>

          <v-alert
            v-if="dialogEvent.allDay"
            class="text-left mt-4 mb-4"
            border="end"
            border-color="#2196f3"
            elevation="2"
            >Весь день</v-alert
          >
          <v-divider
            class="mt-4 mb-4"
            v-if="dialogEvent.extendedProps.description"
          ></v-divider>
          <div v-if="dialogEvent.extendedProps.notify_secret == true">
            <div
              v-if="
                Number(dialogEvent.extendedProps.tgUser_id) ===
                Number(authorName.user.id ? authorName.user.id : authorName.user)
              "
            >
              <div v-if="dialogEvent.extendedProps.image" class="mt-4 mb-4">
                <v-img
                  :src="`https://tgbot.dz72.ru/uploads/${dialogEvent.extendedProps.image}`"
                  class="mb-6"
                ></v-img>
              </div>
              <div
                v-if="dialogEvent.extendedProps.description"
                v-html="dialogEvent.extendedProps.description"
              ></div>
            </div>
            <div v-else></div>
          </div>
          <div v-else>
            <div v-if="dialogEvent.extendedProps.image" class="mt-4 mb-4">
              <v-img
                :src="`https://tgbot.dz72.ru/uploads/${dialogEvent.extendedProps.image}`"
                class="mb-6"
              ></v-img>
            </div>
            <div
              v-if="dialogEvent.extendedProps.description"
              v-html="dialogEvent.extendedProps.description"
            ></div>
          </div>
        </template>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text="Закрыть" variant="text" @click="dialog = false"></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
moment.locale("ru");
import UserService from "../services/user.service.js";

export default defineComponent({
  name: "CalendarBoard",
  sockets: {
    message_chat(data) {
      if (data.drop == true) {
        for (let i = 0; i < this.myEvents.length; i++) {
          if (this.myEvents[i].id === data.id) {
            this.myEvents[i].classNames = "event-remove";
            setTimeout(() => {
              this.myEvents.splice(i, 1);
              this.handleUpdDate();
            }, 2000);
          }
        }
        // let i = Number(this.myEvents.findIndex(({ id }) => id == data.id));
        // this.myEvents.splice(i, 1);
      } else if (data.upd == true) {
        for (let i = 0; i < this.myEvents.length; i++) {
          if (this.myEvents[i].id === data.id) {
            this.myEvents.splice(i, 1);
            setTimeout(() => {
              this.myEvents[i] = data;
              this.handleUpdDate();
            }, 100);
          }
        }
      } else {
        this.myEvents.push(data);
        this.handleUpdDate();
      }
    },
  },
  components: {
    FullCalendar,
  },
  data() {
    return {
      authorName: "",
      tgUser: "",
      dialog: false,
      dialogEvent: null,
      calendar: null,
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        editable: false,
        droppable: false,
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "myLink,dayGridMonth,timeGridWeek,timeGridDay",
        },
        initialView: "dayGridMonth",
        buttonText: {
          today: "Сегодня",
          month: "Месяц",
          week: "Неделя",
          day: "День",
        },
        weekends: true,
        firstDay: 1,
        locale: "ruLocale",
        allDayText: "Весь день",
        eventClick: this.handleDateClick,
        events: this.myEvents,
        height: "auto",
        eventTimeFormat: {
          hour: "2-digit",
          minute: "2-digit",
        },
        customButtons: {
          prev: {
            text: "PREV",
            click: () => {
              let calendarApi = this.$refs.fullCalendar.getApi();
              calendarApi.prev();
              this.startEndDate();
            },
          },
          next: {
            text: "NEXT",
            click: () => {
              let calendarApi = this.$refs.fullCalendar.getApi();
              calendarApi.next();
              this.startEndDate();
            },
          },
          today: {
            text: "Сегодня",
            click: () => {
              let calendarApi = this.$refs.fullCalendar.getApi();
              calendarApi.today();
              this.startEndDate();
            },
          },
          myLink: {
            text: "Показать все события",
            click: this.addNewEvent,
          },
        },
      },
      myEvents: [],
      stat: false,
      formatSchoolItem: ["Очное", "Очно-заочное", "Бронирование"],
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.user;
    },
    checkUserTG() {
      const user = window.Telegram.WebApp.initDataUnsafe;
      const regUser = JSON.parse(sessionStorage.getItem("user"));

      if (Object.keys(user).length === 0 && regUser == null) {
        return false;
      } else if (Object.keys(user).length === 0 && regUser.status == true) {
        return regUser;
      } else {
        return user;
      }
    },
    filteredList() {
      return this.myEvents.filter((events) => {
        return (
          !this.formatSchoolItem.length ||
          this.formatSchoolItem.some((n) => events.formatSchool === n)
        );
      });
    },
  },
  methods: {
    async startEndDate() {
      let calendarApi = this.$refs.fullCalendar.getApi();
      let currentMonth = calendarApi.currentData.dateProfile.activeRange;

      for (let date in this.filteredList) {
        let findDate = moment(this.filteredList[date].start);
        let dow = findDate.isoWeekday();

        let currentDateStart = moment(currentMonth.start).unix();
        let currentDateEnd = moment(currentMonth.end).unix();

        let findDateStart = moment(this.filteredList[date].start).unix();
        let findDateEnd = moment(this.filteredList[date].end).unix();

        if (dow == 6 || dow == 7) {
          if (findDateStart >= currentDateStart && findDateEnd <= currentDateEnd) {
            this.calendarOptions.weekends = true;
          }
        } else {
          this.calendarOptions.weekends = false;
        }
      }
    },
    addNewEvent() {
      if (this.stat == false) {
        this.calendarOptions.customButtons.myLink.text =
          "Не показывать дистанционное обучение";
        this.formatSchoolItem = [];
        this.calendarOptions.events = this.filteredList;
        this.stat = true;
      } else {
        this.stat = false;
        this.calendarOptions.customButtons.myLink.text = "Показать все события";
        this.formatSchoolItem = ["Очное", "Очно-заочное", "Бронирование"];
        this.calendarOptions.events = this.filteredList;
      }
    },
    handleDateClick: function (arg) {
      this.dialog = true;
      this.dialogEvent = arg.event;
    },
    handleUpdDate() {
      let list = window.document.querySelectorAll(".fc-event");
      setTimeout(() => {
        for (var i = 0; i < list.length; i++) {
          list[i].classList.remove("event-active");
          list[i].classList.remove("event-remove");
        }
      }, 1000);
    },
    dateView(date) {
      if (moment(date).format("HH:mm") == "00:00") {
        return moment(date).format("DD MMMM YYYY");
      }
      return moment(date).format("DD MMMM YYYY - HH:mm");
    },
    timeView(time) {
      return moment(time).format("HH:mm");
    },
    dateMachine(date) {
      return moment(date).unix();
    },
    async getCalendar() {
      await UserService.getCalendar().then(
        (response) => {
          this.calendarOptions.events = response;
          this.myEvents = response;
          this.startEndDate();
        },
        () => {
          this.$router.push("/");
        }
      );
    },
    swipeHandler(direction) {
      if (direction == "right") {
        this.calendarOptions.customButtons.prev.click();
      }

      if (direction == "left") {
        this.calendarOptions.customButtons.next.click();
      }
    },
  },
  mounted() {
    if (this.checkUserTG || this.currentUser) {
      this.authorName = this.checkUserTG;
      this.getCalendar();
    } else if (this.currentUser == null && !this.currentUser) {
      this.$router.push("/login");
    }
  },
});
</script>
