<template>
  <v-container fluid tag="section" aria-labelledby="title">
    <v-row>
      <v-col cols="12">
        <v-card class="elevation-0 rounded-md v-card--variant-elevated">
          <v-card-item>
            <div class="v-card-item__content">
              <div class="d-sm-flex align-center justify-space-between">
                <v-card-title class="text-md-h6 text-body-1 text-wrap font-weight-bold"
                  >Просмотр событий</v-card-title
                >
              </div>
            </div>
          </v-card-item>
          <v-divider></v-divider>
          <v-card-text class="elevation-0 rounded-md v-card--variant-elevated">
            <v-row class="mb-3">
              <v-col cols="12" sm="12" md="2">
                <v-text-field
                  v-model="searchField"
                  density="compact"
                  variant="outlined"
                  persistent-hint
                  prepend-inner-icon="mdi-magnify"
                  label="Поиск"
                  hide-details
                ></v-text-field>

                <v-checkbox
                  id="eventFree"
                  name="eventFree"
                  class="mt-3 mb-3"
                  hide-details
                  v-model="checkSelect"
                  label="Простые события"
                  required
                  density="compact"
                  value="free"
                >
                </v-checkbox>

                <v-checkbox
                  id="eventSch"
                  name="eventSch"
                  class="mt-3 mb-3"
                  hide-details
                  v-model="checkSelect"
                  label="Бесплатное обучение"
                  required
                  density="compact"
                  value="Бесплатное обучение"
                >
                </v-checkbox>

                <v-checkbox
                  id="eventSchool"
                  name="eventSchool"
                  class="mt-3 mb-3"
                  hide-details
                  v-model="checkSelect"
                  label="Платное обучение"
                  required
                  density="compact"
                  value="Платное обучение"
                >
                </v-checkbox>
              </v-col>

              <v-col cols="12" sm="12" md="4" offset-md="6">
                <v-btn
                  class="float-right text-none"
                  color="brown-lighten-1"
                  elevation="1"
                  height="40px"
                  prepend-icon="mdi-keyboard-backspace"
                  to="/"
                  density="comfortable"
                  >Назад</v-btn
                >
              </v-col>
            </v-row>

            <v-table
              density="default"
              class="month-table scope elevation-0 rounded border"
              hover
            >
              <thead>
                <tr class="bg-blue-grey-darken-1 text-white month-item text-left">
                  <th scope="col">Заголовок</th>
                  <th scope="col">Дата начала</th>
                  <th scope="col">Очный период</th>
                  <th scope="col">Дата окончания</th>
                  <th scope="col">Информация</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(event, index) in paginatedData"
                  :key="index"
                  class="month-item text-left"
                  hover
                >
                  <td scope="col">{{ event.title }}</td>
                  <td scope="col">{{ dateConvert(event.start) }}</td>
                  <td scope="col">{{ dateConvert(event.end) }}</td>
                  <td scope="col">{{ dateConvert(event.periodEnd) }}</td>
                  <td scope="col">
                    <div class="">
                      <div>
                        Создал(а): {{ event.tgUser
                        }}<span v-if="event.createdAt != null">
                          - {{ dateConvert(event.createdAt) }}</span
                        >
                      </div>
                      <div v-if="event.tgUserUpdate">
                        Изменил(а): {{ event.tgUserUpdate
                        }}<span v-if="event.updatedAt != null">
                          - {{ dateConvert(event.updatedAt) }}</span
                        >
                      </div>
                    </div>
                  </td>
                  <td scope="col" class="td_actions">
                    <div class="d-flex align-center">
                      <div>
                        <div v-if="event.notify_secret == true">
                          <div
                            v-if="
                              Number(event.tgUser_id) ==
                              Number(
                                authorName.user.id ? authorName.user.id : authorName.user
                              )
                            "
                          >
                            <v-icon
                              @click="paginatedData[index].dialogState = true"
                              size="25"
                              color="green-darken-3"
                              >mdi-pencil-outline</v-icon
                            >
                            <v-icon
                              @click="paginatedData[index].dialogDelete = true"
                              size="25"
                              color="deep-orange-darken-3"
                              >mdi-trash-can-outline</v-icon
                            >
                          </div>
                          <div v-else></div>
                        </div>
                        <div v-else>
                          <v-icon
                            @click="paginatedData[index].dialogState = true"
                            size="25"
                            color="green-darken-3"
                            >mdi-pencil-outline</v-icon
                          >
                          <v-icon
                            @click="paginatedData[index].dialogDelete = true"
                            size="25"
                            color="deep-orange-darken-3"
                            >mdi-trash-can-outline</v-icon
                          >
                        </div>

                        <GDialog
                          v-if="paginatedData[index].dialogDelete"
                          max-width="260"
                          scrollable=""
                          v-model="paginatedData[index].dialogDelete"
                        >
                          <div class="dialog">
                            <div class="card-form confirmation">
                              Вы уверены?
                              <div class="confirmation-btn">
                                <v-btn
                                  class="v-btn--flat bg-deep-orange-darken-3 v-btn--density-default v-btn--size-medium v-btn--variant-elevated text-none"
                                  @click="handleDeleteUser(paginatedData[index])"
                                  >Да</v-btn
                                >
                                <v-btn
                                  class="v-btn--flat bg-green-darken-3 v-btn--density-default v-btn--size-medium v-btn--variant-elevated text-none"
                                  @click="paginatedData[index].dialogDelete = false"
                                  >Нет</v-btn
                                >
                              </div>
                            </div>
                          </div>
                        </GDialog>
                      </div>
                    </div>
                  </td>

                  <GDialog
                    eager
                    v-if="paginatedData[index].dialogState"
                    max-width="800"
                    v-model="paginatedData[index].dialogState"
                    ref="modalDialog"
                  >
                    <div class="dialog">
                      <div class="card-form">
                        <v-card-text
                          class="elevation-0 rounded-md v-card--variant-elevated"
                        >
                          <v-form
                            @submit.prevent="
                              handleUpdateData(
                                paginatedData[index],
                                paginatedData[index].description,
                                paginatedData[index].period
                              )
                            "
                            ref="form"
                          >
                            <v-text-field
                              v-model="paginatedData[index].title"
                              class="mb-3"
                              label="Заголовок"
                              variant="outlined"
                              :rules="[(v) => !!v || 'Обязательное поле для заполнения']"
                              required
                              density="compact"
                            ></v-text-field>

                            <v-select
                              v-model="paginatedData[index].address"
                              :items="items"
                              :class="!paginatedData[index].allDay ? 'mb-8' : ''"
                              label="Адрес"
                              variant="outlined"
                              :rules="[(v) => !!v || 'Необходимо выбрать один вариант']"
                              required
                              hide-details
                              density="compact"
                            ></v-select>

                            <v-checkbox
                              id="licenses_popup"
                              name="licenses_popup"
                              class="mt-3 mb-3"
                              hide-details
                              v-model="paginatedData[index].allDay"
                              label="Весь день"
                              required
                              density="compact"
                            >
                            </v-checkbox>

                            <v-row v-if="!paginatedData[index].allDay" class="pb-0 mb-0">
                              <v-col cols="12" class="pb-0">
                                <v-text-field
                                  type="datetime-local"
                                  v-model="paginatedData[index].start"
                                  label="Дата начала"
                                  variant="outlined"
                                  :rules="[(v) => !!v || 'Выберите дату']"
                                  required
                                  density="compact"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" class="pb-0">
                                <v-text-field
                                  type="datetime-local"
                                  class="mb-3"
                                  v-model="paginatedData[index].periodEnd"
                                  label="Дата окончания"
                                  variant="outlined"
                                  :rules="[(v) => !!v || 'Выберите дату']"
                                  required
                                  density="compact"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>

                            <v-row v-if="paginatedData[index].allDay">
                              <v-col cols="12" class="pb-0">
                                <v-text-field
                                  type="date"
                                  v-model="paginatedData[index].start"
                                  label="Дата начала"
                                  variant="outlined"
                                  :rules="[(v) => !!v || 'Выберите дату']"
                                  required
                                  density="compact"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" class="pb-0 mb-3">
                                <v-text-field
                                  type="date"
                                  v-model="paginatedData[index].start"
                                  label="Дата окончания"
                                  variant="outlined"
                                  :rules="[(v) => !!v || 'Выберите дату']"
                                  required
                                  density="compact"
                                  :disabled="paginatedData[index].allDay"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>

                            <v-alert
                              v-if="checkDateStatus"
                              class="mt-3 mb-6 text-left"
                              border="top"
                              type="warning"
                              variant="outlined"
                              prominent
                              density="compact"
                              >{{ checkDate }}
                            </v-alert>

                            <v-row
                              v-if="paginatedData[index].formatSchool != 'Бронирование'"
                              class="mt-0"
                            >
                              <v-col cols="12" class="pb-0 mb-6">
                                <v-select
                                  v-model="paginatedData[index].period"
                                  :items="period"
                                  label="Период очного посещения"
                                  variant="outlined"
                                  :rules="[
                                    (v) => !!v || 'Необходимо выбрать один вариант',
                                  ]"
                                  required
                                  density="compact"
                                  hide-details
                                ></v-select>
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="paginatedData[index].auditor"
                                  class="mb-3"
                                  label="Аудитория"
                                  variant="outlined"
                                  density="compact"
                                ></v-text-field>

                                <v-text-field
                                  v-model="paginatedData[index].contact"
                                  class="mb-3"
                                  label="Контакты"
                                  variant="outlined"
                                  density="compact"
                                ></v-text-field>

                                <v-text-field
                                  v-model="paginatedData[index].urlSite"
                                  class="mb-3"
                                  label="Ссылка на сайт"
                                  variant="outlined"
                                  density="compact"
                                ></v-text-field>

                                <v-text-field
                                  v-model="paginatedData[index].urlprogram"
                                  class="mb-3"
                                  label="Ссылка на программу"
                                  variant="outlined"
                                  density="compact"
                                ></v-text-field>

                                <v-autocomplete
                                  clearable
                                  v-model="paginatedData[index].nameProgramm"
                                  label="Наименование программы"
                                  :items="nameProgrammItem"
                                  class="mb-3"
                                  variant="outlined"
                                  density="compact"
                                >
                                  <template v-slot:no-data>
                                    <div class="px-4">Ничего не найдено</div>
                                  </template>
                                </v-autocomplete>

                                <v-select
                                  v-model="paginatedData[index].formSchool"
                                  :items="formSchoolItem"
                                  label="Форма обучения"
                                  variant="outlined"
                                  class="mb-3"
                                  :rules="[
                                    (v) => !!v || 'Необходимо выбрать один вариант',
                                  ]"
                                  required
                                  density="compact"
                                ></v-select>

                                <v-select
                                  v-model="paginatedData[index].formatSchool"
                                  :items="formatSchoolItem"
                                  label="Формат обучения"
                                  variant="outlined"
                                  class="mb-3"
                                  :rules="[
                                    (v) => !!v || 'Необходимо выбрать один вариант',
                                  ]"
                                  required
                                  density="compact"
                                ></v-select>

                                <v-text-field
                                  type="number"
                                  v-model="paginatedData[index].ballNMO"
                                  class="mb-3"
                                  label="Баллы НМО"
                                  variant="outlined"
                                  density="compact"
                                ></v-text-field>
                                <v-combobox
                                  clearable
                                  v-model="paginatedData[index].specSH"
                                  label="Специальность"
                                  :items="specSHitem"
                                  class="mb-3"
                                  variant="outlined"
                                  :rules="[
                                    (v) =>
                                      !!v ||
                                      'Необходимо выбрать один или более вариантов',
                                  ]"
                                  required
                                  multiple
                                  density="compact"
                                >
                                  <template v-slot:no-data>
                                    <div class="px-4">Ничего не найдено</div>
                                  </template>
                                </v-combobox>
                              </v-col>
                            </v-row>

                            <v-row v-if="paginatedData[index].notify">
                              <v-col cols="12" class="mt-0 mb-6">
                                <div v-if="upFileMess" class="mt-0 mb-6">
                                  <strong>{{ upFileMess }}</strong>
                                </div>
                                <v-file-input
                                  v-if="!paginatedData[index].image && !upFile"
                                  accept="image/png, image/jpeg, image/jpg"
                                  label="Загрузить изображение"
                                  placeholder="Загрузить изображение"
                                  prepend-icon="mdi-camera"
                                  v-model="file"
                                  ref="fill"
                                  variant="outlined"
                                  density="compact"
                                  @change="handleFileUpload"
                                  hide-details
                                  class="mb-6"
                                ></v-file-input>
                                <v-img
                                  v-if="paginatedData[index].image && !upFile"
                                  :src="`https://tgbot.dz72.ru/uploads/${paginatedData[index].image}`"
                                  class="mb-6"
                                ></v-img>
                                <v-img
                                  v-if="!paginatedData[index].image && !upFile"
                                  :src="url"
                                  class="mb-6"
                                ></v-img>
                                <v-img v-if="upFile" :src="url" class="mb-6"></v-img>

                                <v-btn
                                  v-if="file && !upFile"
                                  @click="handleUpload(paginatedData[index])"
                                  class="bg-brown-lighten-1 v-btn--density-default v-btn--size-medium v-btn--variant-elevated mt-3"
                                  >Загрузить изображение
                                </v-btn>
                                <v-btn
                                  v-if="paginatedData[index].image || upFile"
                                  @click="handleUploadReset(paginatedData[index])"
                                  class="bg-brown-lighten-1 v-btn--density-default v-btn--size-medium v-btn--variant-elevated mt-3"
                                  >Удалить изображение
                                </v-btn>
                              </v-col>
                            </v-row>
                            <v-row class="mt-0 mb-6">
                              <v-col cols="12">
                                <QuillEditor
                                  theme="snow"
                                  placeholder="Описание"
                                  :toolbar="toolbarOptions"
                                  label="Описание"
                                  variant="outlined"
                                  v-model:content="paginatedData[index].description"
                                  contentType="html"
                                  density="compact"
                                />
                              </v-col>
                            </v-row>
                            <v-row class="mt-0">
                              <v-col cols="12">
                                <v-alert
                                  v-if="err"
                                  class="text-left"
                                  border="top"
                                  type="warning"
                                  variant="outlined"
                                  prominent
                                  density="compact"
                                  >{{ err }}
                                </v-alert>
                                <v-btn
                                  type="submit"
                                  class="v-btn--flat bg-green-darken-3 v-btn--density-default v-btn--size-medium v-btn--variant-elevated mt-5"
                                  block
                                  :disabled="btnDisable"
                                  :loading="loading"
                                  >Обновить</v-btn
                                >
                              </v-col>
                            </v-row>
                          </v-form>
                          <div
                            v-if="message"
                            class="alert"
                            :class="successful ? 'alert-success' : 'alert-danger'"
                          >
                            {{ message }}
                          </div>
                        </v-card-text>
                      </div>
                    </div>
                  </GDialog>
                </tr>
              </tbody>
            </v-table>
            <v-pagination
              v-if="!searchField && !checkSelect.length"
              v-model="page"
              :length="pageCount"
              color="blue-grey-darken-1"
              density="comfortable"
              class="mt-12"
            ></v-pagination>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import { defineComponent } from "vue";
import UserService from "../services/user.service.js";
import "gitart-vue-dialog/dist/style.css";
import { GDialog } from "gitart-vue-dialog";
import moment from "moment";
moment.locale("ru");
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default {
  name: "EditPage",
  // props: ["authorName"],
  data() {
    return {
      authorName: "",
      name: "Страница администрирования",
      coint_list: [],
      searchField: "",
      dialogState: false,
      dialogDelete: false,
      successful: false,
      message: "",
      UpdateUser: null,
      page: 1,
      pagination_item_per_page: 20,
      pagination_offset: 0,
      events: [],
      items: [
        "ул. ​Юрия Семовских, 12 ст3 - Конференц зал",
        "ул. ​Юрия Семовских, 12 ст3 - Компьютерный класс",
        "ул. Республики, д. 169a",
      ],
      nameProgrammItem: [],
      formSchoolItem: ["Бесплатное обучение", "Платное обучение"],
      formatSchoolItem: [
        "Очное",
        "Очное (с применением симуляционного обучения)",
        "Очно-заочное",
        "Очно-заочное (с применением симуляционного обучения)",
        "Заочное (с применением дистанционных технологий)",
        "Очно-заочное (с применением дистанционных технологий)",
      ],
      specSHitem: [],
      checkDate: "",
      checkDateStatus: false,
      loading: false,
      btnDisable: false,
      err: "",
      checkSelect: [],
      toolbarOptions: [["bold", "italic", "underline", "strike", "link", "clean"]],
      period: ["Без периода", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      checkPeriod: "",
      url: null,
      file: null,
      fill: null,
      upFile: false,
      upFileMess: "",
      errFile: "",
      upFileName: "",
    };
  },
  components: {
    GDialog,
    QuillEditor,
  },
  methods: {
    handleFileUpload(event) {
      this.url = URL.createObjectURL(this.file);
      this.events.find((item) => {
        if (item.id === event.id) {
          item.image = URL.createObjectURL(this.file);
          return true;
        }
      });
    },
    async handleUpload() {
      const file = this.file;

      const formData = new FormData();
      formData.append("file", file);
      await UserService.getUploadImage(formData)
        .then((res) => {
          this.upFile = res.data.file.upload;
          this.upFileName = res.data.file.name;
          this.upFileMess = res.data.file.mess;
        })
        .catch((err) => {
          this.errFile = err;
        });
    },
    handleUploadReset(event) {
      this.events.find((item) => {
        if (item.id === event.id) {
          item.image = "";
          return true;
        }
      });
      this.upFile = false;
      this.upFileMess = "";
      this.url = null;
      this.file = null;
      this.fill = null;
    },
    async getCalendar() {
      await UserService.getCalendar().then(
        (response) => {
          this.events = response.sort(function (a, b) {
            if (moment(a.start).unix() < moment(b.start).unix()) {
              return 1;
            }
            if (moment(a.start).unix() > moment(b.start).unix()) {
              return -1;
            }
            return 0;
          });
          this.viewProgramm();
          this.viewSpec();
        },
        () => {
          this.$router.push("/");
        }
      );
    },
    async viewProgramm() {
      await UserService.getViewProgramm().then((res) => {
        res.data.forEach((element) => {
          this.nameProgrammItem.push(element.nameProgramm);
        });
      });
    },
    async viewSpec() {
      await UserService.getViewSpec().then((res) => {
        res.data.forEach((element) => {
          this.specSHitem.push(element.specSH);
        });
      });
    },
    async handleDeleteUser(deleteData) {
      const data = {
        id: deleteData.id,
        title: deleteData.title,
      };
      await UserService.getDeleteCalendar(data).then(async (res) => {
        if (res.data == true) {
          let drop = data;
          drop.drop = true;
          await this.$socket.emit("message_chat", drop);
          setTimeout(() => ((this.loading = false), (this.dialogState = false)), 1000);
          this.getCalendar();
        } else {
          this.err = "Ошибка удаления";
        }
      });
    },
    async handleUpdateData(updateData, updDesc, updPeriod) {
      if (updPeriod != "Без периода") {
        const time =
          moment(updateData.end).format("HH:mm") != "00:00"
            ? "T" + moment(updateData.end).format("HH:mm")
            : "";
        // const date = moment(updateData.start).format("YYYY-MM-DD");
        const math = moment(updateData.start)
          .add(updPeriod - 1, "day")
          .utc(true)
          .format("YYYY-MM-DD");
        this.checkPeriod = moment(`${math}${time}`).format("YYYY-MM-DD" + `${time}`);
      } else {
        this.checkPeriod = updateData.periodEnd;
      }

      const data = {
        id: updateData.id,
        allDay: updateData.allDay,
        title: updateData.title,
        start: updateData.start,
        end: updateData.allDay ? updateData.start : this.checkPeriod,
        period: updPeriod,
        periodEnd: updateData.allDay ? updateData.start : updateData.periodEnd,
        backgroundColor:
          updateData.address == "ул. ​Юрия Семовских, 12 ст3 - Конференц зал"
            ? "#43A047"
            : updateData.address == "ул. ​Юрия Семовских, 12 ст3 - Компьютерный класс"
            ? "#1B5E20"
            : "#0277BD",
        textColor: "#ffffff",
        display: "backgroundColor",
        description: updDesc,
        address: updateData.address,
        urlSite: updateData.urlSite,
        urlprogramm: updateData.urlprogramm,
        contact: updateData.contact,
        nameProgramm: updateData.nameProgramm,
        formSchool: updateData.formSchool,
        formatSchool: updateData.formatSchool,
        ballNMO: updateData.ballNMO,
        specSH: Array.isArray(updateData.specSH)
          ? updateData.specSH.join(", ")
          : updateData.specSH,
        auditor: updateData.auditor,
        tgUserUpdate: this.authorName.user.id
          ? this.authorName.user.first_name
          : this.authorName.name,
        tgUser_id: this.authorName.user.id
          ? this.authorName.user.id
          : this.authorName.user,
        notify: updateData.notify,
        notify_secret: updateData.notify_secret,
        image: this.upFileName ? this.upFileName : updateData.image,
      };

      const start = moment(data.start).unix();
      const end = moment(data.periodEnd).unix();

      if (end <= start && !data.allDay) {
        this.checkDate = "Дата окончания не может быть меньше или равно даты начала";
        this.checkDateStatus = true;
        this.loading = false;
      } else {
        this.checkDate = "";
        this.checkDateStatus = false;

        await UserService.getUpdateCalendar(data).then(async (res) => {
          const check = res.data;
          if (check == true) {
            this.loading = true;
            let drop = data;
            drop.upd = true;
            drop.classNames = "event-active";
            await this.$socket.emit("message_chat", drop);
            setTimeout(() => ((this.loading = false), (this.dialogState = false)), 1000);
            this.getCalendar();
          } else if (check == false) {
            this.err = "К сожалению эта дата занята.";
          } else {
            this.err = "Ошибка отправки сообщения";
          }
        });
      }
    },
    changePage(page_num) {
      if (page_num === 1) {
        this.$router.push("/admin");
      } else {
        this.$router.push(`/admin/?page=${page_num}`);
      }
    },
    dateConvert(date) {
      if (moment(date).format("HH:mm") == "00:00") {
        return moment(date).format("DD MMMM YYYY");
      }
      return moment(date).format("DD MMMM YYYY - HH:mm");
    },
  },
  computed: {
    dateView(date) {
      if (moment(date).format("HH:mm") == "00:00") {
        return moment(date).format("DD MMMM YYYY");
      }
      return moment(date).format("DD MMMM YYYY - HH:mm");
    },
    loggedIn() {
      return this.$store.state.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.user;
    },
    checkUserTG() {
      const user = window.Telegram.WebApp.initDataUnsafe;
      const regUser = JSON.parse(sessionStorage.getItem("user"));

      if (Object.keys(user).length === 0 && regUser == null) {
        return false;
      } else if (Object.keys(user).length === 0 && regUser.status == true) {
        return regUser;
      } else {
        return user;
      }
    },
    pageCount() {
      let l = this.events.length,
        s = this.pagination_item_per_page;
      return Math.ceil(l / s);
    },
    paginatedData() {
      let start =
        this.pagination_item_per_page * this.page - this.pagination_item_per_page;
      let end = start + this.pagination_item_per_page;

      let ev;

      if (this.searchField != "") {
        ev = this.events.slice();
      } else {
        ev = this.events.slice(start, end);
      }

      return ev
        .filter((content) => {
          return content.title.toLowerCase().includes(this.searchField.toLowerCase());
        })
        .filter((dataFirst) => {
          return (
            !this.checkSelect.length ||
            this.checkSelect.some((n) => dataFirst.formSchool.includes(n))
          );
        });
    },
  },
  mounted() {
    if (this.checkUserTG || this.currentUser) {
      this.authorName = this.checkUserTG;
      this.getCalendar();
    } else if (this.currentUser == null && !this.currentUser) {
      this.$router.push("/login");
    }
  },
};
</script>
