import axios from 'axios';

const API_URL = 'https://tgbot.dz72.ru/api/';

class UserService {
  login(user) {
    return axios
      .post(API_URL + 'signin', {
        pincode: user,
      })
      .then(async response => {
        sessionStorage.setItem('user', JSON.stringify(response.data))
        return response.data;
      });
  }

  getCalendar(data) {
    return axios.post(API_URL + 'view-event', data).then(response => {
      return response.data;
    });
  }

  getCreateCalendar(data) {
    return axios.post(API_URL + 'create-event', data)
  }

  getUploadImage(data) {
    return axios.post(API_URL + 'upload', data)
  }

  getUpdateCalendar(data) {
    return axios.post(API_URL + 'update-event', data)
  }

  getDeleteCalendar(data) {
    return axios.post(API_URL + 'delete-event', data);
  }

  getViewProgramm(data) {
    return axios.post(API_URL + 'view-programm', data);
  }

  getViewSpec(data) {
    return axios.post(API_URL + 'view-spec', data);
  }
}

export default new UserService();