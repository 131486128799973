import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import store from "./store"
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'

const vuetify = createVuetify({
  components,
  directives,
})
import './assets/css/style.css'
import VueSocketIO from 'vue-3-socket.io'
import SocketIO from 'socket.io-client'
import Vue3TouchEvents from "vue3-touch-events";

createApp(App).use(store).use(router).use(vuetify).use(Vue3TouchEvents).use(new VueSocketIO({
  debug: false,
  connection: SocketIO('https://tgbot.dz72.ru', {
    debug: true,
      withCredentials: false,
      autoConnect: true
  }),
  vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_"
  }
})
).mount('#app')
