<template>
  <v-app>
    <v-main>
      <router-view :authorName="tgUser" />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  sockets: {
    connect() {
      console.log("Connected to server");
    },
    disconnect() {
      console.log("Disconnected from server");
    },
  },
  data() {
    return {
      tgUser: "",
    };
  },
  components: {},
  computed: {
    // checkUser() {
    //   const user = window.Telegram.WebApp.initDataUnsafe;
    //   const regUser = JSON.parse(sessionStorage.getItem("user"));
    //   if (Object.keys(user).length === 0 && regUser == null) {
    //     return "";
    //   } else if (Object.keys(user).length === 0 && regUser.status == true) {
    //     return regUser.name;
    //   } else {
    //     return user.user.first_name;
    //   }
    // },
  },
  mounted() {
    this.$socket.connect();
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
