import { createStore } from "vuex";
import UserService from "../services/user.service.js"

const user = JSON.parse(sessionStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

const store = createStore({


  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return UserService.login(user).then(
        user => {
          commit('loginSuccess', user);
          return Promise.resolve(user);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = user.status;
      state.user = user.status;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    }
  }
})

export default store;