import { createWebHistory, createRouter } from "vue-router";
import CalendarBoard from "./components/CalendarBoard.vue";
import CalendarForm from "./components/CalendarForm.vue";
import LoginPage from "./components/LoginPage.vue";
import EditPage from "./components/EditPage.vue";


const routes = [
  {
    path: "/",
    name: "CalendarBoard",
    component: CalendarBoard,
    props: { authorName: '' }
  },
  {
    path: "/CalendarBoard",
    component: CalendarBoard,
    props: { authorName: '' }
  },
  {
    path: "/CalendarForm",
    component: CalendarForm,
    props: { authorName: '' }
  },
  {
    path: "/login",
    component: LoginPage,
    props: { authorName: '' }
  },
  {
    path: "/edit",
    component: EditPage,
    props: { authorName: '' }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;