<template>
  <v-container fluid tag="section" aria-labelledby="title">
    <v-row>
      <v-col cols="12">
        <v-card class="elevation-0 rounded-md v-card--variant-elevated">
          <v-card-item>
            <v-row>
              <v-col cols="12">
                <div
                  class="d-flex justify-space-between ga-8 ga-sm-3 flex-column-reverse flex-sm-row"
                >
                  <v-btn
                    class="float-left text-none"
                    color="brown-lighten-1"
                    elevation="1"
                    prepend-icon="mdi-keyboard-backspace"
                    to="/"
                    density="comfortable"
                    >Назад</v-btn
                  >
                  <v-btn
                    @click="getItems()"
                    class="float-left text-none"
                    color="blue-grey-darken-1"
                    elevation="1"
                    density="comfortable"
                    >Учебно-методический сектор</v-btn
                  >
                </div>
              </v-col>
              <v-divider></v-divider>
              <v-col cols="12">
                <div v-if="!change">
                  <v-card-item-title
                    class="text-md-h6 text-body-1 text-wrap font-weight-bold text-md-left float-left text-left text-none pr-4"
                    >Бронирование комнаты для совещания</v-card-item-title
                  >
                </div>
                <div v-else>
                  <v-card-item-title
                    class="text-md-h6 text-body-1 text-wrap font-weight-bold text-md-left float-left text-left text-none pr-4"
                    >Курсы ДПО/НМО</v-card-item-title
                  >
                </div>
              </v-col>

              <v-col cols="12" v-if="!change">
                <v-checkbox
                  @click="verivyAllDay"
                  id="notify"
                  name="notify"
                  class="mt-0 mb-0"
                  hide-details
                  v-model="dataForm.notify"
                  label="Включить оповещение"
                  required
                  density="compact"
                >
                </v-checkbox>
                <v-checkbox
                  v-if="dataForm.notify"
                  id="notify_secret"
                  name="notify_secret"
                  class="mt-0 mb-0"
                  hide-details
                  v-model="dataForm.notify_secret"
                  label="Скрыть данные"
                  density="compact"
                >
                </v-checkbox>
              </v-col>
            </v-row>
          </v-card-item>

          <v-card-text class="elevation-0 rounded-md v-card--variant-elevated">
            <v-form ref="form">
              <v-text-field
                v-model="dataForm.title"
                class="mb-3"
                label="Заголовок"
                variant="outlined"
                :rules="[(v) => !!v || 'Обязательное поле для заполнения']"
                required
                density="compact"
              ></v-text-field>

              <v-select
                v-model="dataForm.select"
                :items="items"
                label="Адрес"
                variant="outlined"
                :rules="[(v) => !!v || 'Необходимо выбрать один вариант']"
                required
                hide-details
                density="compact"
                :class="dataForm.notify ? 'mb-8' : ''"
              ></v-select>

              <v-checkbox
                v-if="!dataForm.notify"
                id="allday"
                name="allday"
                class="mt-3 mb-3"
                hide-details
                v-model="checked"
                label="Весь день"
                required
                density="compact"
              >
              </v-checkbox>

              <v-row v-if="!checked">
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    type="datetime-local"
                    v-model="dataForm.dateStart"
                    label="Дата начала"
                    variant="outlined"
                    :rules="[(v) => !!v || 'Выберите дату']"
                    required
                    density="compact"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    type="datetime-local"
                    class="mb-3"
                    v-model="dataForm.periodEnd"
                    label="Дата окончания"
                    variant="outlined"
                    :rules="[(v) => !!v || 'Выберите дату']"
                    required
                    density="compact"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row v-if="checked">
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    type="date"
                    v-model="dataForm.dateStart"
                    label="Дата начала"
                    variant="outlined"
                    :rules="[(v) => !!v || 'Выберите дату']"
                    required
                    density="compact"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    type="date"
                    v-model="dataForm.periodEnd"
                    label="Дата окончания"
                    variant="outlined"
                    density="compact"
                    :disabled="checked"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-alert
                    v-if="checkDate"
                    class="text-left"
                    border="top"
                    type="warning"
                    variant="outlined"
                    prominent
                    density="compact"
                    >{{ checkDate }}
                  </v-alert>
                </v-col>
              </v-row>
              <v-row v-if="checkDate">
                <v-col cols="12" class="pt-0">
                  <v-alert
                    class="text-left"
                    border="top"
                    type="warning"
                    variant="outlined"
                    prominent
                    density="compact"
                    >{{ checkDate }}
                  </v-alert>
                </v-col>
              </v-row>

              <v-row v-if="change" class="mt-0">
                <v-col cols="12">
                  <v-select
                    v-model="dataForm.period"
                    :items="period"
                    label="Период очного посещения"
                    variant="outlined"
                    :rules="[(v) => !!v || 'Необходимо выбрать один вариант']"
                    required
                    density="compact"
                    class="mb-3"
                  ></v-select>

                  <v-text-field
                    v-model="dataForm.auditor"
                    class="mb-3"
                    label="Аудитория"
                    variant="outlined"
                    density="compact"
                  ></v-text-field>

                  <v-text-field
                    v-model="dataForm.contact"
                    class="mb-3"
                    label="Контакты"
                    variant="outlined"
                    density="compact"
                  ></v-text-field>

                  <v-text-field
                    v-model="dataForm.urlSite"
                    class="mb-3"
                    label="Ссылка на сайт"
                    variant="outlined"
                    density="compact"
                  ></v-text-field>

                  <v-text-field
                    v-model="dataForm.urlprogramm"
                    class="mb-3"
                    label="Ссылка на программу"
                    variant="outlined"
                    density="compact"
                  ></v-text-field>

                  <v-autocomplete
                    clearable
                    v-model="dataForm.nameProgramm"
                    label="Наименование программы"
                    :items="nameProgrammItem"
                    class="mb-3"
                    variant="outlined"
                    density="compact"
                  >
                    <template v-slot:no-data>
                      <div class="px-4">Ничего не найдено</div>
                    </template>
                  </v-autocomplete>

                  <v-select
                    v-model="dataForm.formSchool"
                    :items="formSchoolItem"
                    label="Форма обучения"
                    variant="outlined"
                    class="mb-3"
                    :rules="[(v) => !!v || 'Необходимо выбрать один вариант']"
                    required
                    density="compact"
                  ></v-select>

                  <v-select
                    v-model="dataForm.formatSchool"
                    :items="formatSchoolItem"
                    label="Формат обучения"
                    variant="outlined"
                    class="mb-3"
                    :rules="[(v) => !!v || 'Необходимо выбрать один вариант']"
                    required
                    density="compact"
                  ></v-select>

                  <v-text-field
                    type="number"
                    v-model="dataForm.ballNMO"
                    class="mb-3"
                    label="Баллы НМО"
                    variant="outlined"
                    density="compact"
                  ></v-text-field>
                  <v-combobox
                    clearable
                    v-model="dataForm.specSH"
                    label="Специальность"
                    :items="specSHitem"
                    class="mb-3"
                    variant="outlined"
                    :rules="[(v) => !!v || 'Необходимо выбрать один или более вариантов']"
                    required
                    multiple
                    density="compact"
                  >
                    <template v-slot:no-data>
                      <div class="px-4">Ничего не найдено</div>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4" v-if="dataForm.notify" class="mt-0 mb-6">
                  <div v-if="upFileMess" class="mt-0 mb-6">
                    <strong>{{ upFileMess }}</strong>
                  </div>
                  <v-file-input
                    v-if="!upFile"
                    accept="image/png, image/jpeg, image/jpg"
                    label="Загрузить изображение"
                    placeholder="Загрузить изображение"
                    prepend-icon="mdi-camera"
                    v-model="file"
                    ref="fill"
                    variant="outlined"
                    density="compact"
                    @change="handleFileUpload"
                    hide-details
                    class="mb-6"
                  ></v-file-input>
                  <v-img v-if="file" :src="url" class="mb-6"></v-img>

                  <v-btn
                    v-if="file && !upFile"
                    @click="handleUpload()"
                    class="bg-brown-lighten-1 v-btn--density-default v-btn--size-medium v-btn--variant-elevated mt-3"
                    >Загрузить изображение
                  </v-btn>
                  <v-btn
                    v-if="upFile"
                    @click="handleUploadReset()"
                    class="bg-brown-lighten-1 v-btn--density-default v-btn--size-medium v-btn--variant-elevated mt-3"
                    >Удалить изображение
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="mt-0 mb-6">
                <v-col cols="12">
                  <QuillEditor
                    theme="snow"
                    placeholder="Описание"
                    :toolbar="toolbarOptions"
                    label="Описание"
                    variant="outlined"
                    v-model:content="dataForm.desc"
                    contentType="html"
                    density="compact"
                  />
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="12">
                  <v-alert
                    v-if="err"
                    class="text-left"
                    border="top"
                    type="warning"
                    variant="outlined"
                    prominent
                    density="compact"
                    >{{ err }}
                  </v-alert>
                  <v-btn
                    class="v-btn--flat bg-green-darken-3 v-btn--density-default v-btn--size-medium v-btn--variant-elevated mt-5"
                    @click="validate"
                    block
                    :disabled="btnDisable"
                    :loading="loading"
                    >Отправить</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserService from "../services/user.service.js";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import moment from "moment";
moment.locale("ru");

export default {
  name: "CalendarForm",
  sockets: {
    message_chat() {},
  },
  components: {
    QuillEditor,
  },
  // props: ["authorName"],
  data() {
    return {
      authorName: "",
      checked: false,
      change: false,
      loading: false,
      url: null,
      file: null,
      fill: null,
      upFile: false,
      upFileMess: "",
      errFile: "",
      dataForm: {
        title: "",
        dateStart: "",
        dateEnd: "",
        period: "Без периода",
        periodEnd: "",
        select: "ул. ​Юрия Семовских, 12 ст3 - Конференц зал",
        desc: "",
        auditor: "",
        urlSite: "",
        urlprogramm: "",
        contact: "",
        nameProgramm: "",
        formSchool: "",
        formatSchool: "",
        ballNMO: "",
        specSH: [],
        notify: false,
        notify_secret: false,
        image: "",
      },
      items: [
        "ул. ​Юрия Семовских, 12 ст3 - Конференц зал",
        "ул. ​Юрия Семовских, 12 ст3 - Компьютерный класс",
        "ул. Республики, д. 169a",
      ],
      nameProgrammItem: [],
      formSchoolItem: ["Бесплатное обучение", "Платное обучение"],
      period: ["Без периода", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      formatSchoolItem: [
        "ВКС",
        "Очное",
        "Очное (с применением симуляционного обучения)",
        "Очно-заочное",
        "Очно-заочное (с применением симуляционного обучения)",
        "Заочное (с применением дистанционных технологий)",
        "Очно-заочное (с применением дистанционных технологий)",
      ],
      specSHitem: [],
      checkDate: "",
      btnDisable: false,
      err: "",
      toolbarOptions: [["bold", "italic", "underline", "strike", "link", "clean"]],
      checkPeriod: "",
    };
  },
  watch: {
    conputedDATE: {
      handler(value) {
        if (value) {
          this.checkDate = "Дата окончания не может быть меньше или равно даты начала";
          this.btnDisable = value;
        } else {
          this.checkDate = "";
          this.btnDisable = value;
        }
      },
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.user;
    },
    checkUserTG() {
      const user = window.Telegram.WebApp.initDataUnsafe;
      const regUser = JSON.parse(sessionStorage.getItem("user"));

      if (Object.keys(user).length === 0 && regUser == null) {
        return false;
      } else if (Object.keys(user).length === 0 && regUser.status == true) {
        return regUser;
      } else {
        return user;
      }
    },
    conputedDATE() {
      const start = moment(this.dataForm.dateStart).unix();
      const end = moment(this.dataForm.periodEnd).unix();

      if (this.dataForm.periodEnd === "" || this.checked) {
        return false;
      } else if (end < start) {
        return true;
      } else if (end === start) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    handleFileUpload() {
      this.url = URL.createObjectURL(this.file);
    },
    async handleUpload() {
      const file = this.$refs.fill.files[0];
      const formData = new FormData();
      formData.append("file", file);
      await UserService.getUploadImage(formData)
        .then((res) => {
          this.upFile = res.data.file.upload;
          this.dataForm.image = res.data.file.name;
          this.upFileMess = res.data.file.mess;
        })
        .catch((err) => {
          this.errFile = err;
        });
    },
    handleUploadReset() {
      this.upFile = false;
      this.dataForm.image = "";
      this.upFileMess = "";
      this.url = null;
      this.file = null;
      this.fill = null;
    },
    verivyAllDay() {
      if (!this.dataForm.notify) {
        this.checked = false;
      }
    },
    async validate() {
      const { valid } = await this.$refs.form.validate();
      if (valid && !this.btnDisable) {
        this.sendForm();
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async sendForm() {
      if (this.dataForm.period != "Без периода") {
        const time =
          moment(this.dataForm.periodEnd).format("HH:mm") != "00:00"
            ? "T" + moment(this.dataForm.periodEnd).format("HH:mm")
            : "";
        // const date = moment(updateData.start).format("YYYY-MM-DD");
        const math = moment(this.dataForm.dateStart)
          .add(this.dataForm.period - 1, "day")
          .utc(true)
          .format("YYYY-MM-DD");
        this.checkPeriod = moment(`${math}${time}`).format("YYYY-MM-DD" + `${time}`);
      } else {
        this.dataForm.periodEnd = moment(this.dataForm.periodEnd)
          .utc(true)
          .format("YYYY-MM-DDTHH:mm");
      }

      if (this.checked) {
        this.dataForm.periodEnd = this.dataForm.dateStart;
        this.dataForm.end = this.dataForm.dateStart;
      }

      const data = {
        allDay: this.checked,
        title: this.dataForm.title,
        start: this.dataForm.dateStart,
        period: this.dataForm.period,
        periodEnd: this.dataForm.periodEnd,
        end: this.checkPeriod != "" ? this.checkPeriod : this.dataForm.periodEnd,
        backgroundColor:
          this.dataForm.select == "ул. ​Юрия Семовских, 12 ст3 - Конференц зал"
            ? "#43A047"
            : this.dataForm.select == "ул. ​Юрия Семовских, 12 ст3 - Компьютерный класс"
            ? "#1B5E20"
            : "#0277BD",
        textColor: "#ffffff",
        display: "backgroundColor",
        description: this.dataForm.desc,
        address: this.dataForm.select,
        urlSite: this.dataForm.urlSite,
        urlprogramm: this.dataForm.urlprogramm,
        contact: this.dataForm.contact,
        nameProgramm: this.dataForm.nameProgramm,
        formSchool: this.dataForm.formSchool == "" ? "free" : this.dataForm.formSchool,
        formatSchool:
          this.dataForm.formatSchool == "" ? "Бронирование" : this.dataForm.formatSchool,
        ballNMO: this.dataForm.ballNMO,
        specSH: this.dataForm.specSH.join(", "),
        auditor: this.dataForm.auditor,
        tgUser: this.authorName.user.id
          ? this.authorName.user.first_name
          : this.authorName.name,
        tgUser_id: this.authorName.user.id
          ? this.authorName.user.id
          : this.authorName.user,
        notify: this.dataForm.notify,
        notify_secret: this.dataForm.notify_secret,
        image: this.dataForm.image,
      };

      console.log(data);

      await UserService.getCreateCalendar(data).then(async (res) => {
        const check = Boolean(res.data);
        if (check == false) {
          console.log("К сожалению эта дата занята.");
          this.loading = false;
          this.err = "К сожалению эта дата занята.";
        } else {
          this.loading = true;
          let newData = data;
          newData.id = res.data.id;
          newData.classNames = "event-active";
          await this.$socket.emit("message_chat", newData);
          setTimeout(() => ((this.loading = false), this.$router.push("/")), 2000);
        }
      });
    },
    async getItems() {
      this.change = !this.change;
      if (this.change == true && this.nameProgrammItem.length == 0) {
        await this.viewProgramm();
        await this.viewSpec();
      }
    },
    async viewProgramm() {
      await UserService.getViewProgramm().then((res) => {
        res.data.forEach((element) => {
          this.nameProgrammItem.push(element.nameProgramm);
        });
      });
    },
    async viewSpec() {
      await UserService.getViewSpec().then((res) => {
        res.data.forEach((element) => {
          this.specSHitem.push(element.specSH);
        });
      });
    },
  },
  mounted() {
    if (this.checkUserTG || this.currentUser) {
      this.authorName = this.checkUserTG;
    } else if (this.currentUser == null && !this.currentUser) {
      this.$router.push("/login");
    }
  },
};
</script>
